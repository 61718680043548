@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*doc
---
title: Carousel
name: carousel
category: Elements
---
Use the following example to create a carousel.

To create a left aligned carousel, add the class `.left` to the `.carousel` wrapper, so it becomes `.carousel left`.

```html_example
<div class="carousel">
	<div class="indicators">
		<h2 class="title">Stories</h2>
		<ol>
			<li class="text active">
				<div class="row align-vertical-center">
					<div class="col-xs-3">
						<img src="/v16/assets/images/slide-small.jpg" alt="" class="circle">
					</div>
					<div class="col-xs-9">
						<p>Lorem ipsum dolor sit amet, cosecteteur adipiscing elit.</p>
					</div>
				</div>
			</li>
			<li class="text">
				<div class="row align-vertical-center">
					<div class="col-xs-3">
						<img src="/v16/assets/images/slide-small.jpg" alt="" class="circle">
					</div>
					<div class="col-xs-9">
						<p>Lorem ipsum dolor sit amet, cosecteteur adipiscing elit.</p>
					</div>
				</div>
			</li>
			<li class="text">
				<div class="row align-vertical-center">
					<div class="col-xs-3">
						<img src="/v16/assets/images/slide-small.jpg" alt="" class="circle">
					</div>
					<div class="col-xs-9">
						<p>Lorem ipsum dolor sit amet, cosecteteur adipiscing elit.</p>
					</div>
				</div>
			</li>
		</ol>
	</div>

	<div class="content bg-dark-gray">
		<div class="item rel active">
			<div class="bg-image">
				<img src="/v16/assets/images/slide-1.jpg" alt="">
			</div>
			<div class="row padding">
				<div class="col-lg-8">
					<blockquote>
						<p>1 Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet.</p>
						<cite title="Source Title">Maecenas tempus, tellus eget condimentum</cite>
					</blockquote>
					<a href="" title="" class="btn btn-red">Lees verder</a>
				</div>
			</div>
		</div>
		<div class="item rel">
			<div class="bg-image">
				<img src="/v16/assets/images/slide-2.jpg" alt="">
			</div>
			<div class="row padding">
				<div class="col-lg-8">
					<blockquote>
						<p>2 Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet.</p>
						<cite title="Source Title">Maecenas tempus, tellus eget condimentum</cite>
					</blockquote>
					<a href="" title="" class="btn btn-red">Lees verder</a>
				</div>
			</div>
		</div>
		<div class="item rel">
			<div class="bg-image">
				<img src="/v16/assets/images/slide-3.jpg" alt="">
			</div>
			<div class="row padding">
				<div class="col-lg-8">
					<blockquote>
						<p>3 Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet.</p>
						<cite title="Source Title">Maecenas tempus, tellus eget condimentum</cite>
					</blockquote>
					<a href="" title="" class="btn btn-red">Lees verder</a>
				</div>
			</div>
		</div>
	</div>
</div>
```
*/
.carousel {
  position: relative;
  margin-top: 4.75em; }
  .carousel.left .indicators {
    right: auto; }
    .carousel.left .indicators ol li {
      margin-right: -.375em;
      text-align: center; }
  .carousel.left .content .item > .row {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .carousel .content {
    color: #ffffff;
    height: 30em; }
    .carousel .content .item {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      height: 100%;
      width: 100%;
      -webkit-transition: all 1.5s linear;
      transition: all 1.5s linear; }
      .carousel .content .item.active {
        opacity: 1;
        visibility: visible; }
      .carousel .content .item .bg-image:after {
        background: rgba(62, 61, 64, 0.25);
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }
      .carousel .content .item .bg-image.dark:after {
        background: rgba(62, 61, 64, 0.5); }
  .carousel .indicators {
    position: relative; }
    .carousel .indicators .title {
      background: #DB4F40;
      color: #ffffff;
      padding-top: .75em;
      padding-bottom: 0.58333em;
      text-align: center;
      width: 100%;
      position: absolute;
      top: -2.5em; }
    .carousel .indicators ol {
      display: none;
      background: #ECEDED;
      list-style: none;
      padding-left: 0;
      width: 100%;
      position: absolute;
      top: 30em;
      z-index: 3;
      width: 100%;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
                  -ms-grid-row-align: stretch;
              align-items: stretch;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .carousel .indicators ol li {
        padding: .5em 0;
        position: relative;
        cursor: pointer;
        background: #ECEDED;
        display: inline-block;
        height: auto; }
        .carousel .indicators ol li img {
          width: 100%; }
        .carousel .indicators ol li .circle {
          margin-right: .5em; }
        .carousel .indicators ol li p {
          margin-bottom: 0; }
        .carousel .indicators ol li.text {
          font-size: .75em; }
          .carousel .indicators ol li.text .row {
            padding-left: 18.75px;
            padding-right: 18.75px; }
            .carousel .indicators ol li.text .row > *:first-child {
              padding-right: 0;
              display: none; }
              @media (min-width: 768px) {
                .carousel .indicators ol li.text .row > *:first-child {
                  display: inline-block; } }
            .carousel .indicators ol li.text .row > *:last-child {
              padding-left: 0;
              padding-right: 0;
              text-align: center;
              width: 100%; }
              @media (min-width: 768px) {
                .carousel .indicators ol li.text .row > *:last-child {
                  padding-left: 18.75px;
                  text-align: left;
                  width: 75%; } }
        .carousel .indicators ol li.heading {
          box-sizing: border-box;
          padding-left: 18.75px;
          padding-right: 18.75px; }
          .carousel .indicators ol li.heading p {
            margin: 0.75em 0; }
        .carousel .indicators ol li.active {
          background: #ffffff; }
        .carousel .indicators ol li.active:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 1.38889em 1.38889em 1.38889em;
          border-color: transparent transparent #ffffff transparent;
          top: -0.83333em;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          margin: auto;
          position: absolute; }

@media (min-width: 576px) {
  .carousel {
    margin-bottom: 4.6em; }
    .carousel .indicators ol {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }

@media (min-width: 918px) {
  .carousel {
    margin-top: 0;
    margin-bottom: 0; }
    .carousel.left .indicators {
      left: 2.08333em; }
      .carousel.left .indicators ol li {
        text-align: left; }
        .carousel.left .indicators ol li.active:before {
          border-width: 1.38889em 0 1.38889em 1.38889em;
          border-color: transparent transparent transparent #ffffff;
          left: auto;
          right: -0.83333em; }
    .carousel.left .item .row > * {
      padding-left: 2.08333em; }
    .carousel .indicators {
      position: absolute;
      width: 30%;
      top: 2.70833em;
      right: 2.08333em;
      z-index: 9; }
      .carousel .indicators .title {
        position: relative;
        top: auto; }
      .carousel .indicators ol {
        display: inline;
        position: relative;
        top: auto;
        width: auto; }
        .carousel .indicators ol li {
          width: 100%;
          margin-right: 0;
          height: auto; }
          .carousel .indicators ol li.text {
            font-size: 1em; }
          .carousel .indicators ol li.active:before {
            border-width: 1.38889em 1.38889em 1.38889em 0;
            border-color: transparent #ffffff transparent transparent;
            left: -0.83333em;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            right: auto; } }

/*doc
---
title: Collapsable
name: collapsable
category: Elements
---


```html_example
<div class="bg-light-gray padding-sides-sm">
	<div data-collapse="starter-specificaties">
		<div class="padding-sm">specificaties</div>
	</div>
	<div id="starter-specificaties" class="collapsed">
		<ul class="list-none">
			<li>7 domeinen</li>
			<li>75GB schijfruimte</li>
			<li>150GB dataverkeer</li>
			<li>512MB geheugen</li>
			<li>14 MySQL databases</li>
		</ul>
	</div>
</div>
```
*/
[data-collapse] {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  [data-collapse]:after {
    position: absolute;
    top: 19.5px;
    right: 0; }

.collapsed {
  display: none; }
  .collapsed.show {
    display: inline-block;
    width: 100%; }

@font-face {
  font-family: 'GTWalsheimBold';
  src: url("../fonts/GTWalsheimBold.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimBold.otf") format("opentype"), url("../fonts/GTWalsheimBold.woff") format("woff"), url("../fonts/GTWalsheimBold.ttf") format("truetype"), url("../fonts/GTWalsheimBold.svg#GTWalsheimBold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'protagonist';
  src: url("../fonts/protagonist.eot?4opk1u");
  src: url("../fonts/protagonist.eot?4opk1u#iefix") format("embedded-opentype"), url("../fonts/protagonist.ttf?4opk1u") format("truetype"), url("../fonts/protagonist.woff?4opk1u") format("woff"), url("../fonts/protagonist.svg?4opk1u#protagonist") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [data-collapse]:after, .checkbox label:after, .list-check li:before, [class*=" icon-"], [data-collapse]:after, .checkbox label:after, .list-check li:before {
  font-family: 'protagonist' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-check:before, .checkbox label:after, .list-check li:before {
  content: "\e900"; }

.icon-cross:before, [data-collapse].show:after {
  content: "\e901"; }

.icon-plus:before, [data-collapse]:after {
  content: "\e902"; }

.icon-star:before {
  content: "\e903"; }

/*doc
---
title: Input fields
name: inputs
category: Forms
---
Every input field can be used with or without a label.
##Text

```html_example
<label for="input_text">Text Input</label>
<input id="input_text" type="text" placeholder="Text Input">
```
##Password

```html_example
<label for="input_password">Password</label>
<input id="input_password" type="password" placeholder="Type your Password">
```
##URL

```html_example
<label for="input_webaddress">Web Address</label>
<input id="input_webaddress" type="url" placeholder="http://yoursite.com">
```
##Email

```html_example
<label for="input_emailaddress">Email Address</label>
<input id="input_emailaddress" type="email" placeholder="name@email.com">
```
##Phone

```html_example
<label for="input_phone">Phone Number</label>
<input id="input_phone" type="tel" placeholder="(999) 999-9999">
```
##Search

```html_example
<label for="input_search">Search</label>
<input id="input_search" type="search" placeholder="Enter Search Term">
```
##Color

```html_example
<label for="ic">Color input</label>
<input type="color" id="ic" value="#000000">
```
##Number

```html_example
<label for="in">Number input</label>
<input type="number" id="in" min="0" max="10" value="5">
```
##Range

```html_example
<label for="ir">Range input</label>
<input type="range" id="ir" value="10">
```
##Date

```html_example
<label for="idd">Date input</label>
<input type="date" id="idd" value="1970-01-01">
```
##Month

```html_example
<label for="idm">Month input</label>
<input type="month" id="idm" value="1970-01">
```
##Week

```html_example
<label for="idw">Week input</label>
<input type="week" id="idw" value="1970-W01">
```
##Datetime

```html_example
<label for="idt">Datetime input</label>
<input type="datetime" id="idt" value="1970-01-01T00:00:00Z">
```
##Datetime local

```html_example
<label for="idtl">Datetime-local input</label>
<input type="datetime-local" id="idtl" value="1970-01-01T00:00">
```
*/
input,
textarea,
select {
  background-color: #ECEDED;
  border: 0;
  box-sizing: border-box;
  font-family: "GTWalsheimBold", sans-serif;
  font-size: 1em;
  margin-bottom: 0.22222em;
  padding: 0.79167em 0.75em 0.75em;
  width: 100%; }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none; }

textarea {
  resize: vertical; }

label {
  display: block; }

/*doc
---
title: Buttons
name: button
category: Forms
---
Button styles can be applied to any element. Typically you'll want to
use either a `<button>` or an `<a>` element:

```html_example
<button class="btn">Button</button>
<a class="btn" href="#">Button link</a>
```

If your button is actually a link to another page, please use the
`<a>` element, while if your button performs an action, such as submitting
a form or triggering some javascript event, then use a `<button>` element.

##Colors

Button                                                           | Modifier Class
---------------------------------------------------------------- | -----------------
<button class="btn">Regular button</button>           					 | `btn`
<button class="btn btn-white">White button</button>              | `btn btn-white`
<button class="btn btn-gray">Gray button</button>              	 | `btn btn-gray`
<button class="btn btn-dark-gray">Dark gray button</button>      | `btn btn-dark-gray`
<button class="btn btn-blue">Blue button</button>                | `btn btn-blue`
<button class="btn btn-red">Red button</button>              		 | `btn btn-red`
<button class="btn btn-green">Green button</button>              | `btn btn-green`

##Sizes

Button                                                           | Modifier Class
---------------------------------------------------------------- | -----------------
<button class="btn sm">Small button</button>          					 | `btn sm`
<button class="btn">Regular button</button>           					 | `btn`
<button class="btn lg">Large button</button>          					 | `btn lg`
<button class="btn xl">Extra large button</button>        			 | `btn xl`
<button class="btn responsive">Full width button</button>        | `btn responsive`
*/
.btn {
  background-color: #000000;
  border: none;
  cursor: pointer;
  color: #ffffff !important;
  display: inline-block;
  font-family: "GTWalsheimBold", sans-serif;
  font-size: 1em;
  padding: 0.54167em 1em 0.5em;
  outline: none;
  position: relative;
  text-decoration: none; }
  .btn:active, .btn:hover {
    text-decoration: none; }
  .btn:disabled {
    cursor: not-allowed;
    opacity: .5; }

.btn-white {
  background-color: #ffffff;
  color: #000000 !important; }
  .btn-white:active, .btn-white:hover {
    background-color: black;
    color: #ffffff !important; }

.btn-dark-gray {
  background-color: #3E3D40;
  color: #ffffff !important; }
  .btn-dark-gray:active, .btn-dark-gray:hover {
    background-color: #f0f0f0;
    color: #3E3D40 !important; }

.btn-gray {
  background-color: #999b9d;
  color: #ffffff !important; }
  .btn-gray:active, .btn-gray:hover {
    background-color: #f0f0f0;
    color: #999b9d !important; }

.btn-blue {
  background-color: #2BACB5;
  color: #ffffff !important; }
  .btn-blue:active, .btn-blue:hover {
    background-color: #f0f0f0;
    color: #2BACB5 !important; }

.btn-green {
  background-color: #81D292;
  color: #ffffff !important; }
  .btn-green:active, .btn-green:hover {
    background-color: #f0f0f0;
    color: #81D292 !important; }

.btn-red {
  background-color: #DB4F40;
  color: #ffffff !important; }
  .btn-red:active, .btn-red:hover {
    background-color: #f0f0f0;
    color: #DB4F40 !important; }

.checkbox label,
.radio label {
  background-color: transparent !important; }
  .checkbox label.bg-light-gray:before,
  .radio label.bg-light-gray:before {
    background-color: #ECEDED; }
  .checkbox label.bg-light-gray:after,
  .radio label.bg-light-gray:after {
    color: #000000 !important; }

.checkbox label,
.radio label {
  background-color: transparent !important; }
  .checkbox label.bg-red:before,
  .radio label.bg-red:before {
    background-color: #DB4F40; }
  .checkbox label.bg-red:after,
  .radio label.bg-red:after {
    color: #ffffff !important; }

.checkbox label,
.radio label {
  background-color: transparent !important; }
  .checkbox label.bg-white:before,
  .radio label.bg-white:before {
    background-color: #ffffff; }
  .checkbox label.bg-white:after,
  .radio label.bg-white:after {
    color: #000000 !important; }

/*doc
---
title: Helper text
name: help
category: Forms
---

```html_example
<label for="input_text">Text Input</label>
<input id="input_text" type="text" placeholder="Text Input">
<span class="help error">This is a form error!</span>
```
*/
.help {
  font-size: 0.83333em;
  display: block;
  margin-bottom: 0.83333em; }
  .help.error {
    color: #DB4F40; }

/*doc
---
title: Select
name: select
category: Forms
---

```html_example
<select>
  <option value="nl">.nl</option>
  <option value="be">.be</option>
  <option value="com">.com</option>
  <option value="net">.net</option>
</select>
```
*/
/*doc
---
title: Checkbox
name: checkbox
category: Forms
---

```html_example
<ul class="list-none">
	<li class="checkbox">
		<input id="checkbox1" name="checkbox" type="checkbox" checked="checked">
		<label for="checkbox1">Choice A</label>
	</li>
	<li class="checkbox">
		<input id="checkbox2" name="checkbox" type="checkbox">
		<label for="checkbox2">Choice B</label>
	</li>
	<li class="checkbox">
		<input id="checkbox3" name="checkbox" type="checkbox">
		<label for="checkbox3">Choice C</label>
	</li>
</ul>
```
*/
/*doc
---
title: Checkbox colors
name: checkbox-colors
category: Forms
---

```html_example
<div class="padding-sides padding-sm">
	<div class="checkbox">
		<input id="checkboxC1" name="checkbox" type="checkbox" checked="checked">
		<label for="checkboxC1" class="bg-red">Choice A</label>
	</div>
</div>
<div class="padding-sides padding-sm">
	<div class="checkbox">
		<input id="checkboxC2" name="checkbox" type="checkbox" checked="checked">
		<label for="checkboxC2" class="bg-light-gray">Choice A</label>
	</div>
</div>
```
*/
/*doc
---
title: Radio
name: radio
category: Forms
---

```html_example
<ul class="list-none">
	<li class="radio">
		<input id="radio1" name="radio" type="radio" checked="checked">
		<label for="radio1">Option 1</label>
	</li>
	<li class="radio">
		<input id="radio2" name="radio" type="radio">
		<label for="radio2">Option 2</label>
	</li>
	<li class="radio">
		<input id="radio3" name="radio" type="radio">
		<label for="radio3">Option 3</label>
	</li>
</ul>
```
*/
/*doc
---
title: Radio colors
name: radio-colors
category: Forms
---

```html_example
<ul class="list-none">
	<li class="radio">
		<input id="radio1" name="radiogroup" type="radio" checked="checked">
		<label for="radio1" class="bg-light-gray">Option 1</label>
	</li>
	<li class="radio">
		<input id="radio2" name="radiogroup" type="radio">
		<label for="radio2" class="bg-red">Option 2</label>
	</li>
	<li class="radio">
		<input id="radio3" name="radiogroup" type="radio">
		<label for="radio3" class="bg-white">Option 3</label>
	</li>
</ul>
```
*/
.checkbox label,
.radio label {
  cursor: pointer;
  padding-left: 1.5em;
  position: relative;
  display: inline; }
  .checkbox label:before, .checkbox label:after,
  .radio label:before,
  .radio label:after {
    font-size: .9em;
    left: 0;
    position: absolute;
    top: 0; }
  .checkbox label:before,
  .radio label:before {
    content: ' ';
    background-color: #ECEDED;
    height: 1em;
    width: 1em;
    padding: 2px; }
  .checkbox label:after,
  .radio label:after {
    color: #000000;
    display: none; }

.checkbox input,
.radio input {
  display: none; }
  .checkbox input:checked + label:after,
  .radio input:checked + label:after {
    display: block; }

.checkbox.disabled,
.radio.disabled {
  opacity: .5; }

.checkbox.align-center label:before, .checkbox.align-center label:after,
.radio.align-center label:before,
.radio.align-center label:after {
  left: 50%;
  margin-left: -.5em; }

.checkbox label:after {
  left: 2px;
  top: 2px;
  width: 1em;
  height: 1em; }

.radio label:before, .radio label:after {
  border-radius: 50%; }

.radio label:after {
  content: '\0025CF';
  text-align: center;
  top: -.33em;
  left: .035em;
  font-size: 1.5em; }

input[type=range] {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  height: 1.5em;
  margin-left: 0;
  width: 100%; }
  input[type=range]:focus {
    outline: none; }
  input[type=range]::-webkit-slider-runnable-track {
    background-color: #000000;
    border: none;
    height: 4px; }
  input[type=range]::-moz-range-track {
    background-color: #000000;
    border: none;
    height: 4px;
    width: 100%; }
  input[type=range]::-ms-track {
    background-color: #000000;
    border: none;
    height: 2px; }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #000000;
    border: 4px solid #000000;
    border-radius: 50%;
    box-shadow: inset 0 0 0 4px #ffffff;
    height: 1.66667em;
    margin-top: -0.77778em;
    width: 1.66667em; }
  input[type=range]::-moz-range-thumb {
    background-color: #000000;
    border: 0.22222em solid #000000;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.22222em #ffffff;
    height: 1.66667em;
    margin-top: -0.77778em;
    width: 1.66667em; }
  input[type=range]::-ms-thumb {
    background-color: #000000;
    border: 0.13889em solid #000000;
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.13889em #ffffff;
    height: 0.83333em;
    width: 0.83333em;
    margin-top: -0.11111em; }

html,
body,
.full-screen,
.full-screen > div {
  height: 100%;
  width: 100%; }

/*doc
---
title: Grid
name: grid
category: Basics
---
Protagonist uses a mobile first, responsive, grid system that apporopriately
scales up to 12 columns and has five breakpoints:

* less than 640px `(xs)`
* 640px and up `(sm)`
* 768px and up `(md)`
* 1024px and up `(lg)`
* 1224px and up `(xl)`

Every `.row` must be placed within a `.container` (fixed-width),
`.container-full` (full-width) or `.full-screen` (full width and height)
for propper alignment. Content should be placed within columns, which have a
class acording to the following format: `.col-{size}-{column-size}`. For
example: `.col-xl-4`.

##Alignment
Columns can be aligned both horizontally and vertically. All combinations
possible.

###Horizontal
####Center
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="33.6" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="80.7" y="10" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-center">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

####Left *default*
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="10" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-left">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

####Right
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="104.2" y="10" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-right">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

###Vertical
####Bottom
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="10" y="41.3" class="st1" width="42.8" height="36.9"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="104.2" y="25.8" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-bottom">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

####Center
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="10" y="26.4" class="st1" width="42.8" height="36.9"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="104.2" y="18.7" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-vertical-center">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

####Full
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="10" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="104.2" y="10" class="st1" width="42.8" height="68.3"/>
</svg>

```htmlimg_example
<div class="row align-full">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

####Top *default*
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 157 88.3" style="enable-background:new 0 0 157 88.3;" xml:space="preserve" width="500">
<style type="text/css">
	.st0{fill:#3E3D40;}
	.st1{fill:#DB4F40;}
</style>
<rect class="st0" width="157" height="88.3"/>
<rect x="10" y="10" class="st1" width="42.8" height="36.9"/>
<rect x="57.1" y="10" class="st1" width="42.8" height="68.3"/>
<rect x="104.2" y="10" class="st1" width="42.8" height="52.4"/>
</svg>

```htmlimg_example
<div class="row align-top">
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
	<div class="col-xs-4">
		...
	</div>
</div>
```

*/
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 18.75px;
  padding-right: 18.75px; }
  .row.align-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
                -ms-grid-row-align: flex-start;
            align-items: flex-start; }
  .row.align-bottom {
    -webkit-box-align: end;
        -ms-flex-align: end;
                -ms-grid-row-align: flex-end;
            align-items: flex-end; }
  .row.align-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: left; }
  .row.align-left {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .row.align-right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-align: left; }
  .row.align-vertical-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
                -ms-grid-row-align: center;
            align-items: center; }
  .row.align-full {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
                -ms-grid-row-align: stretch;
            align-items: stretch; }
  .row > * {
    width: 100%;
    box-sizing: border-box;
    padding-left: 18.75px;
    padding-right: 18.75px; }
    .row > * > .row {
      padding-left: 0;
      padding-right: 0;
      margin-left: -25px;
      margin-right: -25px; }

/*doc
---
title: Positioning
name: position
category: Basics
---
##Relative

```html_example
<div class="rel">
	<div>
		Some content that can be placed relatively to the wrapper div.
	</div>
</div>
```
*/
.rel {
  position: relative; }

/*doc
---
title: Hidden
name: hidden
category: Basics
---
##Relative
```html_example
<div class="hidden">
	<div>
		This div will be hidden.
	</div>
</div>
```
*/
.hidden {
  display: none; }

.container,
.container-full {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

@media (min-width: 576px) {
  .container {
    width: 576px; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 768px; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; } }

@media (min-width: 918px) {
  .container {
    width: 918px; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; } }

@media (min-width: 1185px) {
  .container {
    width: 1185px; }
  .col-xl-1 {
    width: 8.33333%; }
  .col-xl-2 {
    width: 16.66667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.33333%; }
  .col-xl-5 {
    width: 41.66667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.33333%; }
  .col-xl-8 {
    width: 66.66667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.33333%; }
  .col-xl-11 {
    width: 91.66667%; }
  .col-xl-12 {
    width: 100%; } }

.hero {
  background: #DB4F40;
  background-size: cover; }

/*doc
---
title: Background image
name: bg-image
category: Basics
---

```html_example
<div class="rel">
	<div class="bg-image">
		<img src="/v16/assets/images/slide-2.jpg" alt="">
	</div>
	<div class="row padding overlay">
		<div class="col-xs-12 padding-sides-none">
			<p class="margin-bottom">This is an example text to show the background image. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris egestas, orci sed imperdiet mattis, purus lectus scelerisque odio, at cursus orci mi ac tortor.</p>
			<p>This is an example text to show the background image. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris egestas, orci sed imperdiet mattis, purus lectus scelerisque odio, at cursus orci mi ac tortor.</p>
		</div>
	</div>
</div>
```
*/
/*doc
---
title: Background image (dark)
name: bg-image-dark
category: Basics
---

```html_example
<div class="rel">
	<div class="bg-image dark">
		<img src="/v16/assets/images/slide-2.jpg" alt="">
	</div>
	<div class="row padding overlay">
		<div class="col-xs-12 padding-sides-none">
			<p class="margin-bottom">This is an example text to show the background image. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris egestas, orci sed imperdiet mattis, purus lectus scelerisque odio, at cursus orci mi ac tortor.</p>
			<p>This is an example text to show the background image. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris egestas, orci sed imperdiet mattis, purus lectus scelerisque odio, at cursus orci mi ac tortor.</p>
		</div>
	</div>
</div>
```
*/
.bg-image {
  z-index: 0;
  position: absolute;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden; }
  .bg-image:after {
    background: rgba(62, 61, 64, 0.25);
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .bg-image.dark:after {
    background: rgba(62, 61, 64, 0.5); }
  .bg-image img {
    min-height: 100%;
    width: 100%; }
  .bg-image + * {
    position: relative;
    z-index: 2;
    max-height: 100%;
    overflow-y: hidden; }

.overlay {
  color: #ffffff; }

@media (min-width: 768px) {
  .overlay {
    color: #ffffff; } }

img.align-left {
  float: left;
  margin-right: 18.75px; }

img.align-right {
  float: right;
  margin-left: 18.75px; }

.flip {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

/*doc
---
title: Image name (slider)
name: image-name-slider
category: Images
---

```html_example
<img src="/v16/assets/images/photos/cases-tdh-17-slider-480.jpg"
	srcset="/v16/assets/images/photos/cases-tdh-17-slider-480.jpg 480w,
					/v16/assets/images/photos/cases-tdh-17-slider-768.jpg 768w,
					/v16/assets/images/photos/cases-tdh-17-slider-1024.jpg 1024w,
					/v16/assets/images/photos/cases-tdh-17-slider-1224.jpg 1224w,
					/v16/assets/images/photos/cases-tdh-17-slider-1580.jpg 1580w"
	alt="TDH"
>
```
*/
/*doc
---
title: Flip
name: flip
category: Images
---
You can flip an image horizontally by using `.flip`.
*/
/*doc
---
title: Align
name: align
category: Images
---
You can align an image left or right by using `.align-left` and `.align-right` on an image.
*/
/*doc
---
title: Image name (banner)
name: image-name-banner
category: Images
---

```html_example
<img src="/v16/assets/images/photos/cases-evermore-01-banner-480.jpg"
  srcset="/v16/assets/images/photos/cases-evermore-01-banner-480.jpg 480w,
	/v16/assets/images/photos/cases-evermore-01-banner-768.jpg 768w,
	/v16/assets/images/photos/cases-evermore-01-banner-1024.jpg 1024w,
	/v16/assets/images/photos/cases-evermore-01-banner-1224.jpg 1224w,
	/v16/assets/images/photos/cases-evermore-01-banner-1580.jpg 1580w"
  alt="Placeholder"
>
```
*/
/*doc
---
title: Image name (square)
name: image-name-square
category: Images
---
<div class="row padding-sides-none">
	<div class="col-xs-5 padding-sides-none">
	<p><img src="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg"
	  srcset="/v16/assets/images/photos/cases-evermore-01-square-170.jpg 170w,
		/v16/assets/images/photos/cases-evermore-01-square-250.jpg 250w,
		/v16/assets/images/photos/cases-evermore-01-square-300.jpg 300w,
		/v16/assets/images/photos/cases-evermore-01-square-460.jpg 460w"
		class="responsive"
	  alt="Placeholder"
	></p>
	</div>
</div>


```htmlimg_example
<img src="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg"
	srcset="/v16/assets/images/photos/cases-evermore-01-square-170.jpg 170w,
	/v16/assets/images/photos/cases-evermore-01-square-250.jpg 250w,
	/v16/assets/images/photos/cases-evermore-01-square-300.jpg 300w,
	/v16/assets/images/photos/cases-evermore-01-square-460.jpg 460w"
	class="responsive"
	alt="Placeholder"
>
```
*/
/*doc
---
title: Image name (big square)
name: image-name-square-big
category: Images
---
<div class="row padding-sides-none">
	<div class="col-xs-9 padding-sides-none">
	<p><img src="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg"
	  srcset="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg 512w,
		/v16/assets/images/photos/cases-evermore-01-square-big-612.jpg 612w,
		/v16/assets/images/photos/cases-evermore-01-square-big-790.jpg 790w"
		class="responsive"
	  alt="Placeholder"
	></p>
	</div>
</div>


```htmlimg_example
<img src="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg"
	srcset="/v16/assets/images/photos/cases-evermore-01-square-big-512.jpg 512w,
	/v16/assets/images/photos/cases-evermore-01-square-big-612.jpg 612w,
	/v16/assets/images/photos/cases-evermore-01-square-big-790.jpg 790w"
	class="responsive"
	alt="Placeholder"
>
```
*/
/*doc
---
title: Jumbotron
name: jumbotron
category: Elements
---

```html_example
<div class="row rel jumbotron">
	<div class="bg-image">
		<img src="/v16/assets/images/webhosting-foto-TDH.jpg" alt="">
	</div>
	<div class="row padding padding-sides-none overlay">
		<div class="col-xs-12 col-md-8 col-lg-6 col-xl-4">
			<h1 class="margin-bottom-sm">Webhosting</h1>
			<p>Stelt u hoge eisen aan internet toepassingen maar wilt u niet belast worden met de hoge kosten van systeembeheer en een eigen infrastructuur? Met onze hostingpakketten krijgt u kwaliteit voor de beste prijzen en altijd de juiste ondersteuning.</p>
		</div>
		<div class="col-xs-12 col-md-8 col-lg-6 col-xl-4">
			<p class="xs"><a href="" title="" target="_blank">TDH Projects</a>, een bedrijf in kunstof- en metaalconstructies, vertrouwt al sinds 2001 op de hosting van Protagonist.</p>
			<a href="" title="" class="btn btn-red">Bekijk de case</a>
		</div>
	</div>
</div>
```
*/
.jumbotron {
  padding-left: 0;
  padding-right: 0; }
  .jumbotron .bg-image {
    padding-left: 0;
    padding-right: 0;
    max-height: 14em;
    overflow-y: hidden;
    position: relative; }
    .jumbotron .bg-image:after {
      background: none; }
    .jumbotron .bg-image.dark:after {
      background: none; }
  .jumbotron .overlay {
    color: #000000; }
  .jumbotron > .row.padding {
    padding-top: 24.375px;
    padding-bottom: 24.375px; }

@media (min-width: 768px) {
  .jumbotron {
    height: 30em; }
    .jumbotron .bg-image {
      max-height: none;
      position: absolute; }
      .jumbotron .bg-image:after {
        background: rgba(62, 61, 64, 0.25); }
      .jumbotron .bg-image.dark:after {
        background: rgba(62, 61, 64, 0.5); }
    .jumbotron .overlay {
      color: #ffffff; }
    .jumbotron > .row div:last-child {
      position: absolute;
      bottom: 48.75px;
      right: 0; }
    .jumbotron > .row div:only-child {
      position: static; }
    .jumbotron > .row div .row div {
      position: static; } }

/*doc
---
title: Margin
name: margin
category: Spacing
---
##None

```html_example
<p class="margin-bottom-none">No margin below this paragraph.</p>
<p>Another paragraph</p>
```

##Small

```html_example
<p class="margin-bottom-sm">Small margin below this paragraph.</p>
<p>Another paragraph</p>
```
##Normal

```html_example
<p class="margin-bottom">Normal margin below this paragraph.</p>
<p>Another paragraph</p>
```
##Large

```html_example
<p class="margin-bottom-lg">Large margin below this paragraph.</p>
<p>Another paragraph</p>
```
*/
.margin-bottom-none {
  margin-bottom: 0 !important; }

.margin-bottom-sm {
  margin-bottom: 0.83333em; }

.margin-bottom {
  margin-bottom: 2.70833em; }

.margin-bottom-lg {
  margin-bottom: 4.16667em; }

/*doc
---
title: Navigation
name: navbar
category: Elements
---
Navigation is by default placed on a dark background, therefore the white text color.

```html_example
<div id="navigation" role="navigation" class="container">
	<div class="row align-vertical-center nav-bar">
		<div class="col-xs-12">
			<div class="logo">
				<a href="" title="" class="color-red xl">Protagonist</a>
			</div>
			<nav class="nav-menu">
				<span class="menu-btn"></span>
				<ul role="menubar">
					<li role="presentation"><a href="" title="" role="menuitem">Webhosting</a></li>
					<li role="presentation"><a href="" title="" role="menuitem">Webdesign</a></li>
					<li role="presentation"><a href="" title="" role="menuitem">Webshops</a></li>
					<li role="presentation"><a href="" title="" role="menuitem">Support</a></li>
					<li role="presentation"><a href="" title="" role="menuitem" class="login">Login</a></li>
					<li role="presentation"><a href="" title="" role="menuitem" class="shop">Shop <span class="circle">88</span></a></li>
				</ul>
			</nav>
		</div>
	</div>
</div>
```
*/
#header {
  margin-bottom: -3.8em; }
  #header h1 .sub {
    display: none; }

.home .nav-menu {
  margin-top: 2.75em; }
  @media (min-width: 768px) {
    .home .nav-menu {
      margin-top: 3.75em; } }
  .home .nav-menu .menu-btn {
    top: 0 !important; }
    @media (min-width: 768px) {
      .home .nav-menu .menu-btn {
        top: 0.83333em !important; } }

.nav-bar {
  padding-top: .5em;
  padding-bottom: .5em;
  position: relative; }
  .nav-bar .logo {
    margin-right: 1.5em; }
    .nav-bar .logo a {
      text-decoration: none; }
  .nav-bar .nav-menu.open ul {
    display: block; }
  .nav-bar .nav-menu.open .menu-btn:before {
    content: "X"; }
  .nav-bar .nav-menu .menu-btn {
    cursor: pointer;
    display: inline-block;
    font-size: 0.83333em;
    color: #ffffff;
    background-color: #DB4F40;
    padding: 0.54167em 2.16667em 0.5em;
    position: absolute;
    top: 0.83333em;
    right: 37.5px; }
    .nav-bar .nav-menu .menu-btn:before {
      content: 'Menu'; }

.nav-menu ul {
  font-size: 1em;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  display: none;
  margin-top: 1em; }
  .nav-menu ul li {
    display: block;
    padding: 0.75em 0 0.66667em; }
    .nav-menu ul li a {
      color: #ffffff;
      text-decoration: none; }
    .nav-menu ul li .login {
      color: #DB4F40; }
    .nav-menu ul li .shop span {
      font-size: 0.83333em;
      background: #DB4F40;
      display: inline-block;
      height: 1.1em;
      margin-left: .625em;
      padding: .625em .625em;
      text-align: center;
      width: 1.1em; }

@media (min-width: 918px) {
  #header {
    margin-bottom: 0; }
    #header h1 .sub {
      display: inline-block; }
  .home .nav-menu {
    margin-top: 0; }
  .nav-bar .logo, .nav-bar .nav-menu {
    display: inline-block; }
  .nav-bar .shop {
    font-size: 0.83333em;
    padding-top: 0.83333em;
    display: inline-block;
    position: absolute;
    right: 37.5px;
    bottom: 1em; }
  .nav-bar .nav-menu .menu-btn,
  .nav-bar .nav-menu.open .menu-btn {
    display: none; }
  .nav-bar .nav-menu ul {
    display: block;
    margin-top: 0; }
    .nav-bar .nav-menu ul li {
      display: inline-block;
      margin-left: 0.83333em; }
      .nav-bar .nav-menu ul li:first-child {
        margin-left: 0; }
      .nav-bar .nav-menu ul li .login {
        color: #ffffff;
        background-color: #DB4F40;
        font-size: 0.83333em;
        padding: 0.54167em 1.25em 0.5em; } }

@media (min-width: 1185px) {
  .nav-bar .nav-menu ul li {
    margin-left: 1.66667em; } }

.nav-footer li {
  font-size: 0.83333em; }
  .nav-footer li a {
    color: #ffffff;
    text-decoration: none; }

.product:nth-child(odd) {
  background-color: #ECEDED; }

.product-remove {
  cursor: pointer; }

/*--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
-----------------------------------------------------------------------*/
#outdated {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  text-align: center;
  z-index: 1500;
  background-color: #DB4F40;
  color: #ffffff; }
  #outdated h6 {
    font-size: 1.875em;
    margin: 30px 0 10px; }
  #outdated p {
    font-size: 1em;
    margin: 0; }
  #outdated #btnUpdateBrowser {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 300px;
    /*need for IE*/
    background-color: #fff;
    color: #DB4F40;
    text-decoration: none;
    cursor: pointer; }
    #outdated #btnUpdateBrowser:hover {
      background-color: #e6e6e6; }
  #outdated .last {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 20px;
    height: 20px; }
    #outdated .last[dir='rtl'] {
      right: auto !important;
      left: 25px !important; }
  #outdated #btnCloseUpdateBrowser {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    font-size: 36px;
    line-height: 36px; }
  * html #outdated {
    position: absolute; }

/*doc
---
title: Padding
name: padding
category: Spacing
---
##Padding top and bottom

```html_example
<div class="padding">Add padding for top and bottom to this div.</div>
```
##Padding top and bottom (small)

```html_example
<div class="padding-sm">Add a small padding for top and bottom to this div.</div>
```
##Padding none

```html_example
<div class="padding-none">Remove top and bottom padding from this div.</div>
```
##Padding bottom none

```html_example
<div class="padding padding-bottom-none">Add no bottom padding to this div.</div>
```
##Padding left

```html_example
<div class="padding-left">Add a left padding to this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
##Padding right

```html_example
<div class="padding-right">Add a right padding to this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
##Padding right none

```html_example
<div class="padding-right-none">Reset the right padding for this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
##Padding sides

```html_example
<div class="padding-sides">Add padding left and right to this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
##Padding sides small

```html_example
<div class="padding-sides-sm">Add a small padding left and right to this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
##Padding sides none

```html_example
<div class="padding-sides-none">Reset the left and right padding for this div. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sodales eros nec sem hendrerit, non elementum mauris vestibulum.</div>
```
*/
.padding {
  padding-top: 48.75px;
  padding-bottom: 48.75px; }

.padding-sm {
  padding-top: 19.5px;
  padding-bottom: 19.5px; }

.padding-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.padding-bottom-none {
  padding-bottom: 0; }

.padding-left {
  padding-left: 37.5px !important; }

.padding-right {
  padding-right: 37.5px !important; }

.padding-right-none {
  padding-right: 0 !important; }

.padding-sides {
  padding-left: 37.5px !important;
  padding-right: 37.5px !important; }

.padding-sides-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.padding-sides-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/*doc
---
title: Spinner
name: spinner
category: Basics
---

```html_example
<div class="spinner-container">
	<div class="spinner"></div>
	<div class="spinner-text">een ogenblik geduld alstublieft</div>
</div>
```

Small spinner

```html_example
<div class="spinner-container sm">
	<div class="spinner"></div>
</div>
```
*/
.spinner-container {
  height: 10em;
  position: relative;
  padding: 1em 0; }
  .spinner-container.sm {
    font-size: .17em; }

.spinner-text {
  position: absolute;
  bottom: .5em;
  text-align: center;
  width: 100%;
  color: #999b9d; }

.spinner,
.spinner:before,
.spinner:after {
  background: #DB4F40;
  -webkit-animation: load1 1s infinite ease-in-out;
          animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }
  .spinner.color-white,
  .spinner:before.color-white,
  .spinner:after.color-white {
    background: #ffffff; }

.spinner {
  color: #DB4F40;
  text-indent: -9999em;
  margin: 3em auto;
  position: relative;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s; }
  .spinner.color-white {
    color: #ffffff; }
  .spinner:before, .spinner:after {
    position: absolute;
    top: 0;
    content: ''; }
  .spinner:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s; }
  .spinner:after {
    left: 1.5em; }

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

/*doc
---
title: Table
name: table
category: Basics
---

```html_example
<table>
	<thead>
		<tr>
			<th>Table Heading 1</th>
			<th>Table Heading 2</th>
			<th>Table Heading 3</th>
		</tr>
	</thead>

	<tbody>
		<tr>
			<td>Table Cell 1</td>
			<td>Table Cell 2</td>
			<td>Table Cell 3</td>
		</tr>
		<tr>
			<td>Table Cell 1</td>
			<td>Table Cell 2</td>
			<td>Table Cell 3</td>
		</tr>
		<tr>
			<td>Table Cell 1</td>
			<td>Table Cell 2</td>
			<td>Table Cell 3</td>
		</tr>
	</tbody>

	<tfoot>
		<tr>
			<th>Table Footer 1</th>
			<th>Table Footer 2</th>
			<th>Table Footer 3</th>
		</tr>
	</tfoot>

	<caption>Table Caption</caption>
</table>
```
*/
table {
  width: 100%;
  text-align: left; }
  table caption {
    caption-side: bottom;
    color: #4d4d4d;
    font-style: italic; }
  table thead, table tfoot, table caption {
    font-size: 0.83333em; }
  table tbody tr:nth-child(odd) {
    background: #ECEDED; }
  table tbody tr:hover {
    background: #e5e7e7; }
  table td, table th {
    padding: 0.33333em 0.5em; }
  table th {
    font-weight: bold; }

/*doc
---
title: Table (responsive)
name: table-responsive
category: Basics
---

```html_example
<div class="table-responsive">
	<table>
		<thead>
			<tr>
				<th>Table Heading 1</th>
				<th>Table Heading 2</th>
				<th>Table Heading 3</th>
			</tr>
		</thead>

		<tbody>
			<tr>
				<td>Table Cell 1</td>
				<td>Table Cell 2</td>
				<td>Table Cell 3</td>
			</tr>
			<tr>
				<td>Table Cell 1</td>
				<td>Table Cell 2</td>
				<td>Table Cell 3</td>
			</tr>
			<tr>
				<td>Table Cell 1</td>
				<td>Table Cell 2</td>
				<td>Table Cell 3</td>
			</tr>
		</tbody>

		<tfoot>
			<tr>
				<th>Table Footer 1</th>
				<th>Table Footer 2</th>
				<th>Table Footer 3</th>
			</tr>
		</tfoot>

		<caption>Table Caption</caption>
	</table>
</div>
```
*/
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: scroll; } }

.products-table tfoot tr td {
  vertical-align: top; }
  .products-table tfoot tr td.active {
    color: #ffffff;
    background: #DB4F40;
    padding-top: 9.75px;
    padding-bottom: 9.75px;
    border-left: 2px solid #DB4F40;
    border-right: 2px solid #DB4F40; }

.products-table thead tr th {
  vertical-align: bottom; }
  .products-table thead tr th.active {
    color: #ffffff;
    background: #DB4F40;
    padding-top: 19.5px;
    padding-bottom: 19.5px; }

.products-table thead tr th.active, .products-table thead tr td.active {
  border-left: 2px solid #DB4F40;
  border-right: 2px solid #DB4F40; }

.products-table tbody tr td:first-child {
  color: #3E3D40; }

.products-table tbody tr td.active {
  border-left: 2px solid #DB4F40;
  border-right: 2px solid #DB4F40; }

.products-table tbody tr:nth-child(odd) {
  background-color: #ECEDED; }

.products-table tbody tr:last-child th.active, .products-table tbody tr:last-child td.active {
  border-bottom: 2px solid #DB4F40; }

/*doc
---
title: Tabs
name: tabs
category: Elements
---
When you want to use tabs on a red background you can use the classes `tabs dark` together.

```html_example
<section id="tabs">
	<div class="row padding bg-light-gray">
		<div class="col-xs-12">
			<h2 class="align-center">Protagonist biedt <span class="color-red">3</span> soorten hosting aan:</h2>
		</div>
	</div>
	<div class="row padding-sides-none bg-light-gray">
		<div class="col-xs-12">
			<div class="tabs">
				<input id="shared-hosting" type="radio" name="tabs-hosting" checked="checked">
				<label for="shared-hosting">Shared Hosting</label>
				<div class="content">
					<div class="row padding">
						<div class="col-sm-8">
							<h3>Shared Hosting</h3>
							<p class="lg margin-bottom-sm">Uw website online in een paar minuten!</p>
							<p>Shared hosting van Protagonist is een service ontwikkeld voor bedrijven en particulieren die hoge eisen stellen aan internet toepassingen zonder de lasten en kosten die het onderhouden van een eigen infrastructuur met zich mee brengt. Met onze hostingpakketten krijgt u kwaliteit voor de beste prijzen en ondersteuning voor uw vragen.</p>
							<p>Doordat de shared hosting op Solid State Drives (SSD) draait laden pagina’s 200% sneller. Alle pakketten ondersteunen blogs, portfolio’s, zakelijke sites, webwinkels en zelfs complexe database gedreven websites.</p>
							<p class="margin-bottom">Protagonist bestaat sinds 1999 en host meer dan 25.000 domeinen. Neem de stap en vraag onze gedreven professionals in een vrijblijvend consult wat ze voor je kunnen doen. </p>
						</div>
						<div class="col-sm-4">
							<div class="row align-center">
								<div class="col-sm-6">
									<img src="/assets/images/down-arrow.png" alt="" class="responsive circle margin-bottom-sm">
								</div>
							</div>
							<p class="lg margin-bottom-sm align-center">Alle Shared Hosting pakketten zijn inclusief:</p>
							<ul class="">
								<li>Onbeperkt aantal email adressen</li>
								<li>PHP, instelbaar van 5.3 tot 7.0</li>
								<li>Plesk Controlpanel</li>
							</ul>
							<div class="align-center"><a href="" title="" class="btn btn-red">Vraag een consult aan</a></div>
						</div>
					</div>
					<div class="row padding bg-red">
						<div class="row margin-bottom">
							<h2 class=" align-center">Kies het Shared Hosting pakket dat bij u past:</h2>
						</div>
					</div>
				</div>

				<input id="virtual-private-servers" type="radio" name="tabs-hosting">
				<label for="virtual-private-servers">Virtual Private Servers</label>
				<div class="content">
					<div class="row padding">
						<div class="col-sm-8">
							<h3>Virtual Private Servers</h3>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
```
*/
.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -18.75px;
  margin-right: -18.75px; }
  .tabs > label {
    color: #ffffff;
    background-color: #DB4F40;
    padding: 1.08333em 2.16667em 1em;
    cursor: pointer;
    z-index: 1;
    width: 100%; }
  .tabs .content {
    background-color: #ffffff;
    width: 100%; }
  .tabs > input[type=radio] {
    display: none; }
  .tabs > input[type=radio]:checked + label + .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  @media (min-width: 768px) {
    .tabs.dark label {
      background-color: #3E3D40; }
    .tabs > label {
      width: auto; }
    .tabs .content {
      display: none;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
    .tabs > input[type=radio]:checked + label {
      color: #DB4F40;
      background-color: #ffffff; } }
  @media (min-width: 918px) {
    .tabs > label {
      margin-left: 2.08333em; }
      .tabs > label:last-child {
        margin-right: 2.08333em; } }

html {
  background-color: #3E3D40;
  color: #000000;
  overflow-y: scroll; }

body {
  font-family: "GTWalsheimBold", sans-serif;
  font-size: 12px;
  line-height: 1.41176; }
  @media (min-width: 768px) {
    body {
      font-size: 18px;
      line-height: 1.2; } }

::-moz-selection {
  color: #ffffff;
  background: #DB4F40; }

::selection {
  color: #ffffff;
  background: #DB4F40; }

/*doc
---
title: Headings
name: text-headings
category: Typography
---

```html_example
<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>
```
*/
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "GTWalsheimBold", sans-serif; }

h1, .h1 {
  font-size: 1.875em; }

h2, .h2 {
  font-size: 1.875em; }

h3, .h3 {
  font-size: 1.875em; }

h4, .h4 {
  font-size: 1.25em; }

h5, .h5 {
  font-size: 1em; }

h6, .h6 {
  font-size: 1em; }

/*doc
---
title: Inline code
name: text-code
category: Typography
---

```html_example
<code>&lt;div&gt;code&lt;/div&gt;</code>
```
*/
/*doc
---
title: Sample output
name: text-samp
category: Typography
---

```html_example
<samp>This is sample output from a computer program.</samp>
```
*/
/*doc
---
title: Preformatted text
name: text-pre
category: Typography
---

```html_example
<pre>P R E F O R M A T T E D T E X T
! " # $ % & ' ( ) * + , - . /
0 1 2 3 4 5 6 7 8 9 : ; < = > ?
@ A B C D E F G H I J K L M N O
P Q R S T U V W X Y Z [ \ ] ^ _
` a b c d e f g h i j k l m n o
p q r s t u v w x y z { | } ~ </pre>
```
*/
code,
pre,
samp {
  font-family: "Inconsolata", monospace;
  font-size: 1.1875em; }

pre {
  white-space: pre-wrap; }

/*doc
---
title: Links
name: text-links
category: Typography
---

```html_example
This is a <a href="#" title="">link</a>.
```
*/
a:link {
  color: #DB4F40;
  text-decoration: none; }

a:visited {
  color: #DB4F40; }

a:hover, a:active {
  color: #c33425;
  text-decoration: underline; }

/*doc
---
title: Lists
name: text-lists
category: Typography
---

##Definition list

```html_example
<dl>
  <dt>Definition List Title</dt>
  <dd>This is a definition list division.</dd>
</dl>
```
##Ordered list

```html_example
<ol>
  <li>List Item 1</li>
  <li>List Item 2</li>
  <li>List Item 3</li>
</ol>
```
##Unordered list

```html_example
<ul>
  <li>List Item 1</li>
  <li>List Item 2</li>
  <li>List Item 3</li>
</ul>
```
##Unstyled list

```html_example
<ul class="list-none">
  <li>List Item 1</li>
  <li>List Item 2</li>
  <li>List Item 3</li>
</ul>
```
##Checklist

```html_example
<ul class="list-check">
	<li>List Item 1</li>
	<li>List Item 2</li>
	<li>List Item 3</li>
</ul>
```
##Checklist round

```html_example
<ul class="list-check circle">
	<li>List Item 1</li>
	<li>List Item 2</li>
	<li>List Item 3</li>
</ul>
```
*/
ul, ol, dd {
  padding-left: 1.875em; }

ul {
  list-style: square; }

ol {
  list-style: decimal; }

.list-none,
.list-check {
  list-style: none;
  padding-left: 0; }

.list-check li {
  padding-left: 1.3em;
  margin-left: .3em; }
  .list-check li:before {
    color: #DB4F40;
    font-size: 1.2em;
    display: inline-block;
    margin-left: -1.6em;
    margin-right: .3em;
    width: 1.3em; }

.list-check.circle li {
  margin-bottom: .3em; }
  .list-check.circle li:before {
    color: #ffffff;
    background-color: #DB4F40;
    width: 1.3em;
    font-size: .8em;
    text-align: center;
    padding-top: .15em;
    padding-bottom: .15em;
    border-radius: 50%; }

/*doc
---
title: Blockquotes
name: text-blockquotes
category: Typography
---

```html_example
<blockquote>
  <p>A block quotation (also known as a long quotation or extract) is a quotation in a written document, that is set off from the main text as a paragraph, or block of text.</p>
  <p>It is typically distinguished visually using indentation and a different typeface or smaller size quotation. It may or may not include a citation, usually placed at the bottom.</p>
  <cite>Said no one, ever.</cite>
</blockquote>
```
*/
blockquote {
  font-size: 1.40625em;
  margin-bottom: 1em; }
  @media (min-width: 576px) {
    blockquote {
      font-size: 1.875em; } }
  blockquote cite {
    font-size: 0.53333em;
    font-style: normal;
    line-height: 1; }
    blockquote cite:before {
      content: "\2d \2009"; }
  blockquote p {
    margin-bottom: 0.77778em; }
    blockquote p:first-child:before {
      content: "\201C"; }
    blockquote p:last-of-type:after {
      content: "\201D";
      display: inline-block; }

/*doc
---
title: Horizontal rule
name: text-hr
category: Typography
---

```html_example
<hr>
```
*/
hr {
  background-color: #999999;
  border: 0;
  color: #999999;
  height: 1px; }

/*doc
---
title: Marked text
name: text-marked
category: Typography
---

```html_example
The <mark>mark element</mark> indicates a highlight.
```
*/
mark {
  background-color: #DB4F40;
  color: #ffffff;
  padding: 0 3px; }

/*doc
---
title: Keyboard keys
name: text-keyboard
category: Typography
---

```html_example
This is a keyboard key: <kbd>Cmd</kbd>
```
*/
kbd {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  display: inline-block;
  font-family: "Inconsolata", monospace;
  font-size: .9375em;
  padding: .0875em .75em;
  text-shadow: 0 1px 0 #fff; }

/*doc
---
title: Abbreviation
name: text-abbreviation
category: Typography
---

```html_example
Abbreviation: <abbr title="HyperText Markup Language">HTML</abbr>
```
*/
/*doc
---
title: Acronym
name: text-acronym
category: Typography
---

```html_example
Acronym: <acronym title="HyperText Markup Language">HTML</acronym>
```
*/
abbr, acronym {
  border-bottom: 1px dotted;
  cursor: help; }

acronym {
  text-transform: uppercase;
  font-size: 15.3px; }

/*doc
---
title: Emphasis
name: text-emphasis
category: Typography
---

```html_example
<em>This text has added emphasis.</em>
```
*/
/*doc
---
title: Offset
name: text-offset
category: Typography
---

```html_example
The <i>i element</i> is text that is offset from the normal text.
```
*/
em, i {
  font-style: italic; }

small {
  font-size: 0.83333em; }

/*doc
---
title: Strong (important)
name: text-strong-imp
category: Typography
---

```html_example
<strong>Strong is used to indicate strong importance.</strong>
```
*/
/*doc
---
title: Strong
name: text-strong
category: Typography
---

```html_example
The <b>b element</b> is stylistically different text from normal text, without any special importance.
```
*/
strong, b {
  font-weight: 600; }

/*doc
---
title: Subscript
name: text-subscript
category: Typography
---

```html_example
Subscript for things like H<sub>2</sub>O.
```
*/
/*doc
---
title: Superscript
name: text-superscript
category: Typography
---

```html_example
Superscript<sup>TM</sup>.
```
*/
sup, sub {
  font-size: .72em;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.28125em; }

sub {
  top: .28125em; }

p, ul, ol, dd {
  margin-bottom: 1.25em; }

/*doc
---
title: Shadow
name: text-shadow
category: Typography
---

```html_example
<span class="shadow">Text with a shadow.</span>
```
*/
.shadow {
  text-shadow: 1.5px 1.5px rgba(0, 0, 0, 0.3); }

/*doc
---
title: Sizes
name: text-sizes
category: Typography
---

Size                                               | Modifier Class
---------------------------------------------------| ---------------------
<small>This small text for fine print etc.</small> | `<small>...</small>`
<span class="sm">This small text.</span>     			 | `sm`
<span class="md">This medium text.</span>          | `md`
<span class="lg">This large text.</span>           | `lg`
<span class="xl">This extra large text.</span>   	 | `xl`
*/
.sm {
  font-size: 0.83333em; }

.md {
  font-size: 1em; }

.lg {
  font-size: 1.25em; }

.xl {
  font-size: 1.875em; }

/*doc
---
title: Lowercase
name: text-lowercase
category: Typography
---

```html_example
<span class="lowercase">All this text will become lowerCASE.</span>
```
*/
.lowercase {
  text-transform: lowercase; }

/*doc
---
title: Uppercase
name: text-uppercase
category: Typography
---

```html_example
<span class="uppercase">All this text will become upperCASE.</span>
```
*/
.uppercase {
  text-transform: uppercase; }

/*doc
---
title: Alignment
name: text-alignment
category: Typography
---
##Left *default*

```html_example
<p class="align-left">This text will be left aligned.</p>
```
##Center

```html_example
<p class="align-center">This text will be center aligned.</p>
```
##Right

```html_example
<p class="align-right">This text will be right aligned.</p>
```
*/
.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

/*doc
---
title: Circle
name: text-circle
category: Typography
---

```html_example
<p><span class="bg-red color-white circle square">1</span> Product 1</p>
```
*/
.circle {
  border-radius: 50%; }
  .circle.square {
    display: inline-block;
    height: 1.1em;
    padding: .25em;
    text-align: center;
    width: 1.1em; }

/*doc
---
title: Inline
name: inline
category: Basics
---

```html_example
This is a <h1 class="inline">inline heading</h1>.
```
*/
.inline {
  display: inline; }

/*doc
---
title: Responsive
name: responsive
category: Basics
---

```html_example
This is a <a href="#" title="">link</a>.
```
*/
.responsive {
  width: 100%;
  box-sizing: border-box;
  text-align: center; }

/*doc
---
title: Word break
name: word-break
category: Basics
---

```html_example
This is a verylongwordyouknowsowewantittotbreakintoanewlinesoitdisplaysniceinthenewsitedesignitneedsevenmorecharacters.

This is a <span class="break">verylongwordyouknowsowewantittotbreakintoanewlinesoitdisplaysniceinthenewsitedesignitneedsevenmorecharacters</span>.
```
*/
.break {
  word-break: break-word; }

.categories {
  list-style: none;
  padding-left: 0; }
  .categories > li {
    margin-bottom: 1em; }
    .categories > li > a {
      color: #000000; }
    .categories > li > ul {
      list-style: none;
      padding-left: 0; }
  .categories ul {
    margin-bottom: 0; }

.breadcrumbs a {
  color: #fff; }

/*doc
---
title: Colors
name: background
category: Basics
---
We have a few colors that can be used in various contexts.
These can be used to set the background or text color for
specific components and modules on the page.
<div class="row">
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-white"></div>
    <code>.bg-white</code>
		<code>.color-white</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-light-gray"></div>
    <code>.bg-light-gray</code>
    <code>.color-light-gray</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-gray"></div>
    <code>.bg-gray</code>
    <code>.color-gray</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-dark-gray"></div>
    <code>.bg-dark-gray</code>
    <code>.color-dark-gray</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-black"></div>
    <code>.bg-black</code>
    <code>.color-black</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-blue"></div>
    <code>.bg-blue</code>
    <code>.color-blue</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm">
    <div class="docSwatch bg-green"></div>
    <code>.bg-green</code>
    <code>.color-green</code>
  </div>
  <div class="col-xs-6 col-sm-3 margin-bottom-sm padding-right">
    <div class="docSwatch bg-red"></div>
    <code>.bg-red</code>
    <code>.color-red</code>
  </div>
</div>
*/
.color-white {
  color: #ffffff !important; }

.bg-white {
  background-color: #ffffff; }

.color-light-gray {
  color: #ECEDED !important; }

.bg-light-gray {
  background-color: #ECEDED; }

.color-gray {
  color: #999b9d !important; }

.bg-gray {
  background-color: #999b9d; }

.color-dark-gray {
  color: #3E3D40 !important; }

.bg-dark-gray {
  background-color: #3E3D40; }

.color-black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000; }

.color-blue {
  color: #2BACB5 !important; }

.bg-blue {
  background-color: #2BACB5; }

.color-green {
  color: #81D292 !important; }

.bg-green {
  background-color: #81D292; }

.color-red {
  color: #DB4F40 !important; }

.bg-red {
  background-color: #DB4F40; }
